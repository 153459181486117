import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Menu,
  Card,
  CardContent,
  Typography,
  MenuItem,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import SellIcon from '@mui/icons-material/Sell';
import { theme } from '../../../commons/style/theme';

import productsData from '../../../static/json/products.json';

interface IProduct {
  id: number;
  title: string;
  description: string;
  price: number;
  category: {
    id: string;
    name: string;
  };
}

const MenuCategories: React.FC = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [dialog, setDialog] = useState<JSX.Element | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    setProducts(productsData.data.products);
  }, []);

  const handleCategoryClick = (
    categoryName: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setSelectedCategory(categoryName);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCategory(null);
  };

  const getProductsByCategory = (categoryName: string) => {
    return products.filter((product) => product.category.name === categoryName);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      minWidth="100%"
      position="relative"
    >
      <Box>
        <Button
          variant="text"
          onClick={(event) => handleCategoryClick('Todas as categorias', event)}
          sx={{
            fontSize: '12px',
            fontFamily: 'Inter, Roboto, sans-serif',
            fontWeight: 'bold',
            color: theme.colors.white,
          }}
        >
          <MenuIcon sx={{ paddingRight: '5px' }} />
          Todas as categorias
        </Button>
        <Menu
          onClose={handleClose}
          open={Boolean(anchorEl) && selectedCategory === 'Todas as categorias'}
          anchorEl={anchorEl}
        >
          <Box display="flex" flexDirection="column" px={1}>
            {Array.from(
              new Set(products.map((product) => product.category.name))
            ).map((categoryName) => (
              <MenuItem key={categoryName}>
                <Card
                  key={categoryName}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    width: '120px',
                    padding: '8px',
                    boxShadow: 3,
                    mb: 0.5,
                    '&:hover': {
                      boxShadow: 10,
                    },
                  }}
                >
                  <CardContent
                    sx={{
                      padding: 0,
                      '&:last-child': {
                        paddingBottom: 0,
                      },
                    }}
                  >
                    <Button
                      style={{
                        padding: '5px 8px',
                      }}
                      sx={{
                        fontSize: '10px',
                        fontFamily: 'Inter, Roboto, sans-serif',
                        fontWeight: 'bold',
                        color: theme.colors.tertiary,
                        textTransform: 'uppercase',
                        border: '1px solid',
                        borderColor: theme.colors.tertiary,
                      }}
                      onClick={(event) =>
                        handleCategoryClick(categoryName, event)
                      }
                    >
                      {categoryName}
                    </Button>
                  </CardContent>
                </Card>
              </MenuItem>
            ))}
          </Box>
        </Menu>
      </Box>

      <Box
        display="flex"
        justifyContent="start"
        alignItems="center"
        minWidth={'48vw'}
      >
        {Array.from(new Set(products.map((product) => product.category.name)))
          .slice(0, 8)
          .map((categoryName) => (
            <Box key={categoryName} ml={4}>
              <Button
                sx={{
                  fontSize: '12px',
                  fontFamily: 'Inter, Roboto, sans-serif',
                  fontWeight: '500',
                  color: theme.colors.white,
                  '&:hover': {
                    backgroundColor: theme.colors.primary,
                    borderRadius: '5px',
                  },
                }}
                variant="text"
                onClick={(event) => handleCategoryClick(categoryName, event)}
              >
                {categoryName} <ArrowDropDownIcon />
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && selectedCategory === categoryName}
                onClose={handleClose}
                sx={{ mt: 2 }}
              >
                <Box display="flex" flexDirection="column" px={1}>
                  {getProductsByCategory(categoryName).map((product) => (
                    <Card
                      key={product.id}
                      // onClick={() => setDialog(<ProductDialog product={product} onClose={() => setDialog(null)}/>)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        width: '200px',
                        mb: 0.5,
                        padding: '8px',
                        boxShadow: 3,
                        '&:hover': {
                          boxShadow: 10,
                        },
                      }}
                    >
                      <CardContent
                        sx={{
                          padding: 0,
                          '&:last-child': {
                            paddingBottom: 0,
                          },
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: '12.5px',
                            fontFamily: 'Inter, Roboto, sans-serif',
                            fontWeight: 'bold',
                            color: theme.colors.quartiary,
                          }}
                        >
                          {product.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: '11px',
                            fontFamily: 'Inter, Roboto, sans-serif',
                            color: 'text.secondary',
                            marginTop: '4px',
                          }}
                          style={{ color: theme.colors.black }}
                        >
                          {product.description}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            pt: 0.2,
                            textAlign: 'center',
                            fontSize: '12.5px',
                            fontFamily: 'Inter, Roboto, sans-serif',
                            fontWeight: 'bold',
                            color: theme.colors.tertiary,
                          }}
                        >
                          A partir de: {product.price}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Menu>
            </Box>
          ))}
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        right={0}
      >
        <Button
          variant="text"
          sx={{
            fontSize: '12px',
            fontFamily: 'Inter, Roboto, sans-serif',
            fontWeight: 'bold',
            color: theme.colors.white,
          }}
        >
          <SellIcon sx={{ paddingRight: '5px' }} />
          Ofertas
        </Button>
      </Box>
      {dialog}
    </Box>
  );
};

export default MenuCategories;
