import { useEffect } from 'react';

import Loader from 'components/Loader';
import Filter from 'components/Filter/Filter';
import Products from 'components/Products';
import Cart from 'components/Cart';
import FloatButtonService from 'components/FloatButtonService';
import ActionBar from 'components/ActionBar';
import CallToAction from 'components/CallToAction';
import Soluctions from 'components/Soluctions/Soluctions';
import Footer from 'components/Footer';
import Hero from 'components/Hero/Hero';

import { useProducts } from 'contexts/products-context';
import * as S from './style';
import { Box, Container, useMediaQuery } from '@mui/material';
import { theme } from 'commons/style/theme';

function App() {
  const { isFetching, products, fetchProducts } = useProducts();

  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.mobile})`);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <S.Container>
      <Hero />
      {isFetching && <Loader />}
      <Soluctions />
      <Container>
        {isMobile && <Filter />}
        <S.TwoColumnGrid>
          {!isMobile && (
            <S.Side>
              <Filter />
            </S.Side>
          )}
          <Box>
            <S.MainHeader>
              <p>{products?.length} Produto(s) encontrados</p>
            </S.MainHeader>
            <Products products={products} />
          </Box>
        </S.TwoColumnGrid>
      </Container>
      <Cart />
      <ActionBar />
      <CallToAction />
      <FloatButtonService />
      <Footer />
    </S.Container>
  );
}

export default App;
