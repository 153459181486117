import { IProduct } from 'models';
import { Box, Container, useMediaQuery } from '@mui/material';
import ProductCard from './Product/productCard';
import { useMemo } from 'react';
import Carousel from 'react-material-ui-carousel';
import { theme } from 'commons/style/theme';

interface IProps {
  products: IProduct[];
}

const Products = ({ products }: IProps) => {
  const groupedProducts = useMemo(() => {
    const grouped: { [key: string]: IProduct[] } = {};
    products.forEach((p) => {
      const category = p.category.name;
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(p);
    });
    return grouped;
  }, [products]);

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile})`);

  const renderGroupedProducts = () => {
    return Object.keys(groupedProducts).map((category) => (
      <Container key={category}>
        <Box
          component="h1"
          mt={3}
          sx={{
            fontSize: '16px',
            marginBottom: '10px',
            color: theme.colors.tertiary,
            [`@media (max-width: ${theme.breakpoints.mobile})`]: {
              fontSize: '15px',
              marginLeft: '10px',
            },
            [`@media (max-width: ${theme.breakpoints.tablet})`]: {
              marginLeft: '10px',
            },
          }}
        >
          {category}
        </Box>

        {isMobile ? (
          <Carousel interval={50000} indicators={false}>
            {groupedProducts[category].map((p) => (
              <ProductCard product={p} key={p.id} />
            ))}
          </Carousel>
        ) : (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: 0.5,
              [`@media (max-width: ${theme.breakpoints.mobile})`]: {
                gridTemplateColumns: 'repeat(1, 1fr)',
              },
              [`@media (min-width: ${theme.breakpoints.tablet}) and (max-width: ${theme.breakpoints.desktop})`]:
                {
                  gridTemplateColumns: 'repeat(1, 1fr)',
                },
            }}
          >
            {groupedProducts[category].map((p) => (
              <ProductCard product={p} key={p.id} />
            ))}
          </Box>
        )}
      </Container>
    ));
  };

  return <>{renderGroupedProducts()}</>;
};

export default Products;
