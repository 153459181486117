import React, { useState } from 'react';
import { Box, Button, Modal, Typography, TextField } from '@mui/material';
import LocalizacaoOpcaoDialog, { EnderecoModoEntrega } from './LocalizacaoOpcaoDialog';
import Logo from '../../../static/logos/logoMarrom.png';
import { theme } from '../../../commons/style/theme';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '25px',
  transform: 'translate(-50%, -50%)',
  width: '28%',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
  p: 4,
  transition: 'all 0.3s ease-in-out',
};

type LocalizacaoDialogProps = {
  endereco: {
    rua: string, 
    numero: string,
  }
  onClose: () => void;
  onSelectedInfo: (info: EnderecoModoEntrega) => void;
};

export default function LocalizacaoDialog({
  endereco,
  onClose,
  onSelectedInfo,
}: LocalizacaoDialogProps) {
  const [rua, setRua] = useState(endereco.rua);
  const [numero, setNumero] = useState(endereco.numero);
  const [errorMessage, setErrorMessage] = useState('');
  const [dialog, setDialog] = useState<JSX.Element | null>(null);

  const handleContinuar = () => {
    if (!rua || !numero) {
      setErrorMessage('Por favor, preencha todos os campos.');
      return;
    }

    setDialog(
      <LocalizacaoOpcaoDialog
        enderecoRua={rua}
        enderecoNumero={numero}
        onClose={() => setDialog(null)}
        onSelectedInfo={onSelectedInfo}
      />
    );
  };

  return (
    <>
      <Modal open={true} onClose={onClose}>
        <Box sx={style}>
          <Box sx={{ mb: 2, textAlign: 'center' }}>
            <img
              src={Logo}
              style={{
                width: '100%',
                maxWidth: '300px',
                margin: '-110px auto',
                borderRadius: '15px',
                display: 'block',
              }}
              alt="logo"
            />
          </Box>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              color: theme.colors.primary,
              textAlign: 'center',
              fontFamily: 'unset',
            }}
          >
            Bem vindo!
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              mb: 2,
              color: 'primary.main',
              textAlign: 'center',
              fontFamily: 'unset',
            }}
          >
            Antes de continuar, insira seu endereço e número.
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              maxHeight: '300px',
              overflowY: 'auto',
              borderRadius: '12px',
              p: 2,
              bgcolor: 'background.default',
            }}
          >
            <TextField
              variant="outlined"
              label="Endereço"
              value={rua}
              onChange={(e) => setRua(e.target.value)}
              fullWidth
              sx={{ mb: 0 }}
            />
            <TextField
              variant="outlined"
              label="Número"
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
              fullWidth
              sx={{ mb: 0 }}
            />
            {errorMessage && (
              <Typography
                variant="body2"
                sx={{ color: 'red', textAlign: 'center' }}
              >
                {errorMessage}
              </Typography>
            )}
            <Typography
              variant="subtitle2"
              sx={{ mb: 2, color: 'primary.main', textAlign: 'left' }}
            >
               Preencha todas as informaçoes acima para uma melhor experiência ;)
            </Typography>
          </Box>

          <Box sx={{ mt: 0, display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{
                borderRadius: '20px',
                backgroundColor: theme.colors.primary,
                color: '#fff',
                padding: '15px',
                width: '100%',
                maxWidth: '95%',
              }}
              onClick={handleContinuar}
            >
              CONTINUAR
            </Button>
          </Box>
          <Typography
            variant="subtitle2"
            sx={{
              mt: 2,
              color: 'primary.main',
              textAlign: 'center',
              fontFamily: 'unset',
            }}
          >
            Os preços e estoque podem variar de acordo com a localidade
          </Typography>
        </Box>
      </Modal>
      {dialog}
    </>
  );
}
